import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import(/* webpackChunkName: "login_home_welome" */ 'components/login/Login')
const Home = () => import(/* webpackChunkName: "login_home_welome" */ 'components/home/Home')
const Welcome = () => import(/* webpackChunkName: "login_home_welome" */ 'components/home/welcome/Welcome')

const Users = () => import(/* webpackChunkName: "Users_Rights_Roles" */ 'components/home/users/Users')
const Artists = () => import(/* webpackChunkName: "Users_Rights_Roles" */ 'components/home/users/Artists')
const Rights = () => import(/* webpackChunkName: "Users_Rights_Roles" */ 'components/home/power/rights/Rights')
const Roles = () => import(/* webpackChunkName: "Users_Rights_Roles" */ 'components/home/power/roles/Roles')

const Cate = () => import(/* webpackChunkName: "Cate_Params" */ 'components/home/goods/cate/Cate')
const Chain = () => import(/* webpackChunkName: "Cate_Params" */ 'components/home/goods/chain/Chain')
// const Params = () => import(/* webpackChunkName: "Cate_Params" */ 'components/home/goods/params/Params')

const GoodsList = () => import(/* webpackChunkName: "GoodsList_Add" */ 'components/home/goods/list/List')
const GoodsAdd = () => import(/* webpackChunkName: "GoodsList_Add" */ 'components/home/goods/list/children/Add')

const ArtworksList = () => import(/* webpackChunkName: "ArtworksList_Add" */ 'components/home/products/list/List')
const ArtworksAdd = () => import(/* webpackChunkName: "ArtworksList_Add" */ 'components/home/products/list/children/Add')
const ArtworksTurn = () => import(/* webpackChunkName: "ArtworksList_Add" */ 'components/home/products/list/artTurn')

const Rules = () => import(/* webpackChunkName: "RuleList_Add" */ 'components/home/products/rules/Rules')
const Bills = () => import(/* webpackChunkName: "RuleList_Add" */ 'components/home/products/bill/Bills')
const Operators = () => import(/* webpackChunkName: "RuleList_Add" */ 'components/home/products/operators/list')
const GrabStatis = () => import(/* webpackChunkName: "RuleList_Add" */ 'components/home/products/statistical/list')

const Appeals = () => import(/* webpackChunkName: "AppealList_Add" */ 'components/home/appeal/Appeal')

// 空投管理
const Transfer = () => import(/* webpackChunkName: "AppealList_Add" */ 'components/home/transfer/index')
// 盲盒管理
const BindIndex = () => import(/* webpackChunkName: "AppealList_Add" */ 'components/home/bindbox/Index')
const AddBindIndex = () => import(/* webpackChunkName: "AppealList_Add" */ 'components/home/bindbox/children/AddBindBox')
const AddartWork = () => import(/* webpackChunkName: "AppealList_Add" */ 'components/home/bindbox/children/AddartWork')
const BindBoxDetail = () => import(/* webpackChunkName: "AppealList_Add" */ 'components/home/bindbox/children/BindBoxDetail')


const Certs = () => import(/* webpackChunkName: "CertList_Add" */ 'components/home/cert/Cert')

const Rotemaps = () => import(/* webpackChunkName: "CertList_Add" */ 'components/home/rotemap/Rotemap')

const Order = () => import(/* webpackChunkName: "Order_Report" */ 'components/home/order/Order')
const Report = () => import(/* webpackChunkName: "Order_Report" */ 'components/home/report/Report')
const Faq = () => import(/* webpackChunkName: "Order_Report" */ 'components/home/faq/faqlist')
const About = () => import(/* webpackChunkName: "Order_Report" */ 'components/home/about/about')
const Notice = () => import(/* webpackChunkName: "Order_Report" */ 'components/home/notice/noticelist')
const Top = () => import(/* webpackChunkName: "Order_Report" */ 'components/home/help/top')
// 合成管理
const Synthetic = () => import('components/home/goods/synthetic/Index')
const SyntheticAdd = () => import('components/home/goods/synthetic/Add')
//系列
const Series = () => import('components/home/goods/series/Index')
const SeriesAdd = () => import('components/home/goods/series/Add')
//extract
const extract = () => import('components/home/goods/extract/Index')
//义链
const Wish = () => import('components/home/wish/Wish')
const WishBatch = () => import('components/home/wish/WishBatch')
const WishPraise = () => import('components/home/wish/WishPraise')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      {
        path: '/welcome',
        component: Welcome
      },
      {
        path: '/users',
        component: Users
      },
      {
        path: '/artists',
        component: Artists
      },
      {
        path: '/rights',
        component: Rights
      },
      {
        path: '/roles',
        component: Roles
      },
      {
        path: '/categories',
        component: Cate
      },
      {
        path: '/chains',
        component: Chain
      },
      {
        path: '/goods',
        component: GoodsList
      },
      {
        path: '/goods/add/:id?',
        component: GoodsAdd
      },
      {
        path: '/orders',
        component: Order
      },
      {
        path: '/reports',
        component: Report
      },
      {
        path: '/artworks',
        component: ArtworksList
      },
      {
        path: '/artworks/add/:id?',
        component: ArtworksAdd
      },
      {
        path: '/rules',
        component: Rules
      },
      {
        path: '/bills',
        component: Bills
      },
      {
        path: '/appeals',
        component: Appeals
      },
      {
        path: '/certs',
        component: Certs
      }
      ,
      {
        path: '/rotemaps',
        component: Rotemaps
      }
      ,
      {
        path: '/faq',
        component: Faq
      }
      ,
      {
        path: '/terms',
        component: About
      },
      {
        path: '/aboutus',
        component: About
      },
      {
        path: '/aboutgrab',
        component: About
      },
      {
        path: '/public_welfare',
        component: About
      },
      {
        path: '/operators',
        component: Operators,
      },
      {
        path: '/grabstatis',
        component: GrabStatis,
      },
      {
        path: '/privacy',
        component: About,
      },
      {
        path: '/policy',
        component: About,
      },
      {
        path: '/transfer',
        component: Transfer
      },
      {
        path: '/bindbox',
        component: BindIndex
      },
      {
        path: '/bindbox/add-bind-box/:id?',
        component: AddBindIndex
      },
      {
        path: '/bindbox/add-artwork/:id?',
        component: AddartWork
      },
      {
        path: '/bindbox/detail/:id?',
        component: BindBoxDetail
      },
      {
        path: '/notice',
        component: Notice
      },
      {
        path: '/artturn',
        component: ArtworksTurn
      },
      {
        path: '/synthetic',
        component: Synthetic
      },
      {
        path: '/synthetic-add/:id?',
        component: SyntheticAdd
      },
      {
        path: '/series',
        component: Series
      },
      {
        path: '/top',
        component: Top
      },
      {
        path: '/series-add/:id?',
        component: SeriesAdd
      },
      {
        path: '/extract',
        component: extract
      },
      {
        path: '/wish',
        component: Wish
      },
      {
        path: '/wish-batch',
        component: WishBatch
      },
      {
        path: '/wish-praise',
        component: WishPraise
      }
    ]
  }
]
const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // to 将访问哪一个路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数,表示放行
  //   next() 放行 next('/login') 强制跳转
  
  if (to.path === '/login') return next()

  var myCookie = document.cookie
  let role = myCookie.split(';')[0].split('=')[1]
  if(!role){
    next('/login')
  }

  next()
})

export default router
