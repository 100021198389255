import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import TreeTable from 'vue-table-with-tree-grid'
// import document from '@/common/webClick'
// 导入字体图标
import './assets/fonts/iconfont.css'
// 导入全局样式表
import './assets/css/global.css'
// 导入富文本编辑器

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
// 导入NProgress 对应的js和css
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import axios from 'axios'
import cookie from 'vue-cookie'

import { Loading } from 'element-ui';
Vue.use(Loading.directive);

Vue.prototype.$http = axios.create({
  validateStatus: function (status) {
    return status >= 200; // 默认值
  }
})

// 在request拦截器中展示进度条 NProgress.start()
// axios.defaults.baseURL = 'https://lianghj.top:8888/api/private/v1/'
// Vue.prototype.$http.defaults.baseURL = 'http://admin.rivvoo.xyz/'
// axios.defaults.baseURL = 'https://www.liulongbin.top:8888/api/private/v1/'
Vue.prototype.$http.interceptors.request.use(config => {
  NProgress.start()
  // 登录授权 请求验证是否有token  需要授权的 API ，必须在请求头中使用 `Authorization` 字段提供 `token` 令牌
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config // 必须返回否则没有值
})
// 在reponse拦截器中,隐藏进度条 NProgress.done()
Vue.prototype.$http.interceptors.response.use(config => {
  NProgress.done()
  //token过期
  if(config.status == 401){
    window.location.href='/#/login'
  }
  return config
},err=>{
  NProgress.done()
})


Vue.config.productionTip = false
//全局cookie信息存储
Vue.prototype.$cookie = cookie; 

Vue.component('tree-table', TreeTable)
// 将富文本编辑器注册为全局组件
Vue.use(VueQuillEditor)
// 全局时间过滤器
Vue.filter('dataFormat', function (originVal) {
  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  // yyyy-mm-dd hh:mm:ss
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})
//上传图片
// 正式存储桶信息
Vue.prototype.uploadCos = {bucket:'prod-pub-1307094776',region:'ap-beijing',bucketPrivate:'prod-private-1307094776'}
// 测试存储桶
// Vue.prototype.uploadCos = {bucket:'rivvoo-1307094776',region:'ap-beijing',bucketPrivate:'identification-1307094776'}
Vue.prototype.uploadUrl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '') + "/api/v1/admin/upload-file"

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
